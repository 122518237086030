/* You can add global styles to this file, and also import other style files */

//styles from primeng
@import '../node_modules/primeng/resources/primeng.min.css';
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

//we can change the default theme here
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
//local set up of layout
@import "../src/app/shared/styles/layout/layout.scss";

/* Core Data Grid CSS */
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import '../node_modules/ag-grid-community/styles/ag-theme-quartz.css';


.layout-container {
    min-height: 100vh;
    overflow-x: hidden
}


@media screen and (min-width: 1960px) {

    .layout-content,
    .landing-wrapper,
    .layout-topbar {
        width: 1504px;
        margin-left: auto !important;
        margin-right: auto !important
    }
}

.layout-content-wrapper {
    padding: 0.5rem;
    padding-top: 0.25rem;
}

.layout-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--topbar-bg-color);
    box-shadow: var(--topbar-box-shadow);
    min-height: 4.4rem;
    padding: 1rem 0;
    width: 100%
}

.layout-topbar .topbar-start {
    display: flex;
    align-items: center;
    padding: 0 1.15rem 0 17.15rem
}

.layout-topbar .topbar-start .topbar-menubutton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    transition: background-color var(--transition-duration)
}

.layout-topbar .topbar-start .topbar-menubutton i {
    font-size: 1.25rem;
    color: var(--topbar-item-text-color);
    transition: color var(--transition-duration)
}

.layout-topbar .topbar-start .topbar-menubutton:hover {
    background-color: var(--primary-color)
}

.layout-topbar .topbar-start .topbar-menubutton:hover i {
    color: var(--primary-color-text)
}

.layout-topbar .topbar-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0 1.15rem 0 .85rem;
    list-style: none;
    flex-grow: 1;
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li {
    margin-left: 1.5rem
}

.layout-topbar .topbar-menu li.topbar-item {
    margin-left: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative
}

.layout-topbar .topbar-menu li.topbar-item img {
    width: 2rem;
    height: 2rem
}

.layout-topbar .topbar-menu li.topbar-item button {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: box-shadow var(--transition-duration)
}

.layout-topbar .topbar-menu li.topbar-item button:focus {
    box-shadow: var(--focus-ring)
}

.layout-topbar .topbar-menu li.topbar-item ul {
    position: absolute;
    bottom: -9.4rem;
    right: 0;
    display: none;
    color: var(--topbar-item-text-color);
    background-color: var(--topbar-popup-item-bg-color);
    box-shadow: var(--topbar-popup-item-shadow)
}

.layout-topbar .topbar-menu li.topbar-item ul.active-topbar-menu {
    display: block
}

.layout-topbar .topbar-menu li.topbar-item ul a {
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-input-icon-left i {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-input-icon-left input::placeholder {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-inputtext {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: var(--topbar-item-text-color);
    border-color: var(--topbar-border-color);
    background-color: var(--topbar-input-bg-color)
}

.layout-topbar .topbar-menu li button span {
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li.topbar-search .p-input-icon-left i {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-search .p-input-icon-left input::placeholder {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: var(--topbar-item-text-color);
    border-color: var(--topbar-border-color);
    background-color: var(--topbar-input-bg-color)
}

@media screen and (max-width: 991px) {
    .blocked-scroll {
        overflow: hidden
    }

    .layout-container .layout-content-wrapper {
        margin-left: 0;
        padding: 1rem
    }

    .layout-container .layout-sidebar {
        z-index: 999;
        transform: translate(-100%);
        transition: transform .3s cubic-bezier(0, 0, .2, 1);
        box-shadow: none
    }

    .layout-container .layout-sidebar .layout-menu-container {
        overflow: auto
    }

    .layout-container .layout-topbar .topbar-start {
        padding-left: 1.15rem
    }

    .layout-container.layout-mobile-active .layout-sidebar {
        transform: translate(0)
    }

    .layout-container.layout-mobile-active .layout-mask {
        display: block;
        animation: fadein var(--transition-duration)
    }

    .layout-container .layout-mask {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        width: 100%;
        height: 100%;
        background-color: var(--maskbg)
    }

    .layout-container .topbar-breadcrumb {
        display: none
    }

    .layout-container .content-breadcrumb {
        display: block
    }
}

@media screen and (min-width: 1960px) {

    .layout-content,
    .landing-wrapper,
    .layout-topbar {
        width: 1504px;
        margin-left: auto !important;
        margin-right: auto !important
    }
}