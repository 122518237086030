.layout-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 16rem;
    background: var(--menu-bg);
    border-right: var(--sidebar-border);
    box-shadow: var(--sidebar-shadow);
    display: flex;
    flex-direction: column
}

.layout-sidebar .sidebar-header {
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.layout-sidebar .sidebar-header .app-logo .app-logo-normal {
    display: inline;
    height: auto;
    width: 50%
}

.layout-sidebar .sidebar-header .app-logo .app-logo-single {
    display: none
}

.layout-sidebar .layout-menu-container {
    overflow: auto;
    flex: 1;
    padding-bottom: 2rem
}

.layout-sidebar .layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    font-size: .857rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--root-menuitem-text-color);
    padding: 1rem 1.25rem 1rem 2rem
}

.layout-sidebar .layout-menu .layout-root-menuitem>a {
    display: none
}

.layout-sidebar .layout-menu a {
    -webkit-user-select: none;
    user-select: none
}

.layout-sidebar .layout-menu a.active-menuitem>.layout-submenu-toggler {
    transform: rotate(-180deg)
}

.layout-sidebar .layout-menu li.active-menuitem>a .layout-submenu-toggler {
    transform: rotate(-180deg)
}

.layout-sidebar .layout-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.layout-sidebar .layout-menu ul a {
    display: flex;
    align-items: center;
    position: relative;
    outline: 0 none;
    color: var(--menuitem-text-color);
    cursor: pointer;
    padding: .75rem 1.25rem .75rem 2rem;
    transition: all var(--transition-duration)
}

.layout-sidebar .layout-menu ul a .layout-menuitem-icon {
    margin-right: .5rem;
    color: var(--menuitem-icon-color)
}

.layout-sidebar .layout-menu ul a .layout-submenu-toggler {
    font-size: 75%;
    margin-left: auto;
    transition: transform var(--transition-duration)
}

.layout-sidebar .layout-menu ul a.active-route,.layout-sidebar .layout-menu ul a.active-route i {
    color: var(--primary-color)
}

.layout-sidebar W:hover {
    background-color: var(--menuitem-hover-bg-color);
    color: var(--primary-600)
}

.layout-sidebar .layout-menu ul a:hover i {
    color: var(--primary-600)
}

.layout-sidebar .layout-menu ul ul {
    overflow: hidden;
    border-radius: var(--border-radius)
}

.layout-sidebar .layout-menu ul ul li a {
    padding-left: 2.5rem
}

.layout-sidebar .layout-menu ul ul li li a {
    padding-left: 3rem
}

.layout-sidebar .layout-menu ul ul li li li a {
    padding-left: 3.5rem
}

.layout-sidebar .layout-menu ul ul li li li li a {
    padding-left: 4rem
}

.layout-sidebar .layout-menu ul ul li li li li li a {
    padding-left: 5.5rem
}

.layout-sidebar .layout-menu ul ul li li li li li li a {
    padding-left: 5rem
}

@media screen and (min-width: 992px) {
    .layout-container.layout-drawer .layout-topbar .topbar-menubutton {
        display:none
    }

    .layout-container.layout-drawer .layout-sidebar {
        height: 100%;
        top: 0;
        transition: width .3s cubic-bezier(0,0,.2,1);
        width: 5.25rem;
        z-index: 999
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu-container {
        overflow: hidden
    }

    .layout-container.layout-drawer .layout-sidebar .sidebar-header {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 1.5rem 0rem
    }

    .layout-container.layout-drawer .layout-sidebar .sidebar-header .app-logo .app-logo-single {
        display: inline;
        order: 1
    }

    .layout-container.layout-drawer .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
        display: none;
        width: 0;
        transition: width .2s cubic-bezier(0,0,.2,1);
        transition-delay: .3s
    }

    .layout-container.layout-drawer .layout-sidebar .sidebar-header .layout-sidebar-anchor {
        display: none;
        width: 1.15rem;
        height: 1.15rem;
        border-radius: 50%;
        border: 2px solid var(--divider-color);
        background-color: transparent;
        outline: none;
        transition: background-color var(--transition-duration),transform .3s
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu {
        transition: all .4s
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
        opacity: 0;
        white-space: nowrap;
        transition: all .1s
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>span {
        margin-right: auto
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text .layout-menuitem-icon {
        font-size: 1.25rem;
        width: 1.25rem
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
        display: block;
        margin-right: .125rem;
        font-size: 1.25rem
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu ul {
        margin: 0;
        padding: 0;
        list-style-type: none
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu ul a {
        padding: .75rem 2rem;
        width: 1.25rem
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu ul a span {
        opacity: 0;
        white-space: nowrap;
        transition: all .1s
    }

    .layout-container.layout-drawer .layout-sidebar .layout-menu ul a .layout-menuitem-icon {
        font-size: 1.25rem;
        width: 1.25rem
    }

    .layout-container.layout-drawer.layout-drawer .layout-content-wrapper {
        margin-left: 5.25rem;
        transition: margin-left .3s cubic-bezier(0,0,.2,1);
        overflow-x: hidden
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar {
        width: 16rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu-container {
        overflow: auto
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header {
        padding: 1.5rem 2rem;
        justify-content: space-between
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
        display: block;
        width: 75%;
        transition: width .2s cubic-bezier(0,0,.2,1);
        transition-delay: .3s
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-single {
        display: none
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .layout-sidebar-anchor {
        display: block;
        animation: px-fadein .15s linear;
        border: 2px solid var(--primary-color)
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
        padding: 1rem 1.25rem 1rem 2rem;
        opacity: 1;
        white-space: nowrap;
        transition: all .3s
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
        display: none
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a {
        padding: .75rem 1.25rem .75rem 2rem;
        width: auto
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a .layout-submenu-toggler {
        display: block
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a span {
        opacity: 1;
        white-space: nowrap;
        transition: all .3s
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul {
        overflow: hidden;
        border-radius: var(--border-radius)
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li a {
        padding-left: 2.5rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li a {
        padding-left: 3rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li a {
        padding-left: 3.5rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li a {
        padding-left: 4rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li a {
        padding-left: 5.5rem
    }

    .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li li a {
        padding-left: 5rem
    }

    .layout-container.layout-drawer.layout-sidebar-anchored .sidebar-header .layout-sidebar-anchor {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color)
    }

    .layout-container.layout-drawer.layout-sidebar-anchored .layout-content-wrapper {
        margin-left: 16rem
    }
}

.layout-light-menu {
    --menu-bg: #ffffff;
    --root-menuitem-text-color: #293241;
    --menuitem-icon-color: rgba(41, 50, 65, .8);
    --menuitem-text-color: rgba(41, 50, 65, .8);
    --submenu-item-hover-bg-color: none;
    --popup-submenu-item-hover-bg-color: none;
    --menuShadow: 0px 10px 40px rgba(41, 50, 65, .06)
}
.layout-light {
    --sidebar-shadow: 0px 10px 40px rgba(41, 50, 65, .06);
    --sidebar-border: none;
    --card-shadow: 0px 10px 40px rgba(41, 50, 65, .06)
}

.layout-transparent-topbar {
    --topbar-bg-color: transparent;
    --topbar-border-color: var(--surface-border);
    --topbar-box-shadow: none;
    --topbar-item-text-color: var(--text-color);
    --topbar-item-text-color-secondary: var(--text-color-secondary);
    --topbar-input-bg-color: var(--surface-border);
    --topbar-popup-item-bg-color: var(--surface-card);
    --topbar-popup-item-shadow: 0 5px 12px 6px #00000017
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
        >.layout-menuitem-root-text {
            font-size: .857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: .75rem 0;
        }

        >a {
            display: none;
        }
    }

    a {
        user-select: none;

        &.active-menuitem {
            >.layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    li.active-menuitem {
        >a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: var(--text-color);
            cursor: pointer;
            padding: .75rem 1rem;
            border-radius: $borderRadius;
            transition: background-color $transitionDuration, box-shadow $transitionDuration;

            .layout-menuitem-icon {
                margin-right: .5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform $transitionDuration;
            }

            &.active-route {
                font-weight: 700;
                color: var(--primary-color);
            }

            // &:hover {
            //     background-color: none;
            // }

            // &:focus {
            //     @include focused-inset();
            // }
        }

        ul {
            overflow: hidden;
            border-radius: $borderRadius;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.layout-mobile-active{
    .layout-sidebar-anchor.anchor-button{
        display: none;
    }
}