// .layout-topbar {
//     position: fixed;
//     height: 5rem;
//     z-index: 997;
//     left: 0;
//     top: 0;
//     width: 100%;
//     padding: 0 2rem;
//     background-color: var(--surface-card);
//     transition: left $transitionDuration;
//     display: flex;
//     align-items: center;
//     box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);

//     .layout-topbar-logo {
//         display: flex;
//         align-items: center;
//         color: var(--surface-900);
//         font-size: 1.5rem;
//         font-weight: 500;
//         width: 300px;
//         border-radius: 12px;

//         img {
//             height: 2.5rem;
//             margin-right: .5rem;
//         }

//         &:focus {
//             @include focused();
//         }
//     }

//     .layout-topbar-button {
//         display: inline-flex;
//         justify-content: center;
//         align-items: center;
//         position: relative;
//         color: var(--text-color-secondary);
//         border-radius: 50%;
//         width: 3rem;
//         height: 3rem;
//         cursor: pointer;
//         transition: background-color $transitionDuration;

//         &:hover {
//             color: var(--text-color);
//             background-color: var(--surface-hover);
//         }

//         &:focus {
//             @include focused();
//         }

//         i {
//             font-size: 1.5rem;
//         }

//         span {
//             font-size: 1rem;
//             display: none;
//         }
//     }

//     .layout-menu-button {
//         margin-left: 2rem;
//     }

//     .layout-topbar-menu-button {
//         display: none;

//         i {
//             font-size: 1.25rem;
//         }
//     }

//     .layout-topbar-menu {
//         margin: 0 0 0 auto;
//         padding: 0;
//         list-style: none;
//         display: flex;

//         .layout-topbar-button {
//             margin-left: 1rem;
//         }
//     }
// }

.layout-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--topbar-bg-color);
    box-shadow: var(--topbar-box-shadow);
    min-height: 4.4rem;
    padding: 1rem 0;
    width: 100%
}

.layout-topbar .topbar-start {
    display: flex;
    align-items: center;
    padding: 0 1.15rem 0 17.15rem
}

.layout-topbar .topbar-start .topbar-menubutton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    transition: background-color var(--transition-duration)
}

.layout-topbar .topbar-start .topbar-menubutton i {
    font-size: 1.25rem;
    color: var(--topbar-item-text-color);
    transition: color var(--transition-duration)
}

.layout-topbar .topbar-start .topbar-menubutton:hover {
    background-color: var(--primary-color)
}

.layout-topbar .topbar-start .topbar-menubutton:hover i {
    color: var(--primary-color-text)
}

.layout-topbar .topbar-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0 1.15rem 0 .85rem;
    list-style: none;
    flex-grow: 1;
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li {
    margin-left: 1.5rem
}

.layout-topbar .topbar-menu li.topbar-item {
    margin-left: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative
}

.layout-topbar .topbar-menu li.topbar-item img {
    width: 2rem;
    height: 2rem
}

.layout-topbar .topbar-menu li.topbar-item button {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: box-shadow var(--transition-duration)
}

.layout-topbar .topbar-menu li.topbar-item button:focus {
    box-shadow: var(--focus-ring)
}

.layout-topbar .topbar-menu li.topbar-item ul {
    position: absolute;
    bottom: -9.4rem;
    right: 0;
    display: none;
    color: var(--topbar-item-text-color);
    background-color: var(--topbar-popup-item-bg-color);
    box-shadow: var(--topbar-popup-item-shadow)
}

.layout-topbar .topbar-menu li.topbar-item ul.active-topbar-menu {
    display: block
}

.layout-topbar .topbar-menu li.topbar-item ul a {
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-input-icon-left i {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-input-icon-left input::placeholder {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-item ul .p-inputtext {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: var(--topbar-item-text-color);
    border-color: var(--topbar-border-color);
    background-color: var(--topbar-input-bg-color)
}

.layout-topbar .topbar-menu li button span {
    color: var(--topbar-item-text-color)
}

.layout-topbar .topbar-menu li.topbar-search .p-input-icon-left i {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-search .p-input-icon-left input::placeholder {
    color: var(--topbar-item-text-color-secondary)
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: var(--topbar-item-text-color);
    border-color: var(--topbar-border-color);
    background-color: var(--topbar-input-bg-color)
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 1;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            &.layout-topbar-menu-mobile-active {
                display: block
            }

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}
